import { createRouter, Navigate } from '@tanstack/react-router';
import {
  indexRoute,
  rootRoute,
  welcomeRoute,
} from '@agyt/client/backoffice/feature/common';
import { paymentsRoutes } from '@agyt/client/backoffice/feature/payments';
import { fxRoutes } from '@agyt/client/backoffice/feature/currency-exchange';
import { usersRoutes } from '@agyt/client/backoffice/feature/users';

const routeTree = rootRoute.addChildren([
  indexRoute.addChildren([paymentsRoutes, fxRoutes, usersRoutes]), // This is purposefully composed here and not in the indexRoute definition to provide a clear global route tree
  welcomeRoute,
]);

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  context: { auth: undefined! },
  defaultNotFoundComponent: () => <Navigate to="/welcome" />,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: unknown; // TODO: Define the type
  }
}
