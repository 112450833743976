import { useHttpClient } from '@agyt/client/backoffice/core/http';
import {
  FxMarginPayloadBody,
  FxMarginResponse,
  FxMarginsCriteriaQuery,
  SuccessApiResponse,
} from '@agyt/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const FX_MARGINS_CACHE_KEY = 'fx-margins';

export function useFindFxMargins() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async ({
      id,
      filters,
    }: {
      id?: string;
      filters?: Partial<FxMarginsCriteriaQuery>;
    }) => {
      try {
        const res = await httpClient.get('/fx/margins', {
          params: { accountId: id, filter: filters },
        });
        return res?.data as SuccessApiResponse<FxMarginResponse[]>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'findFxMargins',
    },
  });
}

export function useFindFxMarginFeeById() {
  const { httpClient } = useHttpClient();

  return useMutation({
    mutationFn: async ({
      id,
      accountId,
    }: {
      id?: string;
      accountId?: string;
    }) => {
      try {
        const res = await httpClient.get(`/fx/margins/${id}`, {
          params: { accountId },
        });
        return res?.data as SuccessApiResponse<FxMarginResponse>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'findFxMargins',
    },
  });
}

export function useUpdateFxMargin() {
  const { httpClient } = useHttpClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: FxMarginPayloadBody) => {
      try {
        const res = await httpClient.post('/fx/margins', data);
        return res.data as SuccessApiResponse<FxMarginResponse>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'updateFxMargin',
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [FX_MARGINS_CACHE_KEY],
      });
    },
  });
}
