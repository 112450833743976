import { Link } from '@tanstack/react-router';

import { Button, NavigationItem } from '@agyt/client/shared/ui/components';
import {
  IconArrowLeftRight,
  IconLogout,
  IconUsers,
  IconWallet,
  Logo,
} from '@agyt/client/shared/ui/icons';
import { useAuth0 } from '@auth0/auth0-react';

export function Navigation() {
  const { logout } = useAuth0();

  return (
    <div className="flex w-[296px] min-w-[296px] flex-col overflow-auto border-r border-slate-200 bg-white p-10">
      <div className="mb-4 2xl:mb-[50px]">
        <Link to="/accounts">
          <Logo />
        </Link>
      </div>
      <nav className="mb-8 text-slate-500 2xl:mb-16">
        <ul>
          <li>
            <Link to="/accounts" className="group">
              <NavigationItem title="Accounts" icon={<IconUsers />} />
            </Link>
          </li>
          <li>
            <Link to="/payments" className="group">
              <NavigationItem title="Payment Fees" icon={<IconWallet />} />
            </Link>
          </li>
          <li>
            <Link to="/currency-exchanges" className="group">
              <NavigationItem
                title="Currency Exchanges"
                icon={<IconArrowLeftRight />}
              />
            </Link>
          </li>
        </ul>
      </nav>
      <div className="flex flex-1 flex-col justify-end">
        <Button
          variant="nav"
          size="nav"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <NavigationItem title="Logout" icon={<IconLogout />} />
        </Button>
      </div>
    </div>
  );
}

export default Navigation;
