import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { HttpClientProvider } from '@agyt/client/backoffice/core/http';
import { Toaster } from '@agyt/client/shared/ui/components';

import App from './app/app';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const domain = process.env.NX_PUBLIC_AUTH0_DOMAIN || '';
const clientId = process.env.NX_PUBLIC_BACKOFFICE_AUTH0_CLIENT_ID || '';
const audience = process.env.NX_PUBLIC_AUTH0_AUDIENCE || '';

root.render(
  <StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
    >
      <HttpClientProvider>
        <App />
        <Toaster />
      </HttpClientProvider>
    </Auth0Provider>
  </StrictMode>,
);
