import { createRoute, useNavigate } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/backoffice/feature/common';
import {
  AccountPicker,
  Button,
  CurrencyPicker,
  DataTable,
  Label,
  PageWrapper,
} from '@agyt/client/shared/ui/components';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@agyt/client/shared/ui/components';
import { useFindPaymentFees } from '@agyt/client/backoffice/data-access/api';
import { PaymentFee } from '@agyt/shared/types';
import { useEffect, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaymentsDetailsPage from './payments-details-page';
import PaymentTypePicker from './payment-type-picker';
import { useFilters } from './table-filters';
import { PaymentTypes } from '@agyt/shared/util/common';

const paymentsRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/payments',
  validateSearch: (search: Record<string, unknown>): { accountId?: string } =>
    search.accountId
      ? {
          accountId: String(search.accountId),
        }
      : {},
});

const paymentsIndexRoute = createRoute({
  getParentRoute: () => paymentsRoot,
  path: '/',
  component: PaymentsPage,
});

export const paymentsDetailsRoute = createRoute({
  getParentRoute: () => paymentsRoot,
  path: '/$id',
  component: PaymentsDetailsPage,
  validateSearch: (
    search: Record<string, unknown>,
  ): { accountId?: string; returnTo?: string } =>
    search.accountId || search.returnTo
      ? {
          accountId: String(search.accountId),
          returnTo: String(search.returnTo),
        }
      : {},
});

export const paymentsRoutes = paymentsRoot.addChildren([
  paymentsIndexRoute,
  paymentsDetailsRoute,
]);

function PaymentsPage() {
  const { filters, resetFilters, setFilters } = useFilters(paymentsRoot.id);
  const navigate = useNavigate({ from: '/payments/$id' });
  const searchParams = paymentsRoot.useSearch();
  const [defaultPaymentFees, setDefaultPaymentFees] = useState<PaymentFee[]>(
    [],
  );
  const [accountPaymentFees, setAccountPaymentFees] = useState<PaymentFee[]>(
    [],
  );
  const [accountId, setAccountId] = useState<string | undefined>(
    searchParams.accountId,
  );
  const { mutateAsync: fetchPaymentFees } = useFindPaymentFees();

  useEffect(() => {
    const getPaymentFees = async () => {
      try {
        const results = await fetchPaymentFees({ filters });
        setDefaultPaymentFees(results?.data);
      } catch (err) {
        console.log(err);
      }
    };
    getPaymentFees();
  }, [filters, fetchPaymentFees]);

  useEffect(() => {
    const getPaymentFeesByAccountId = async () => {
      if (accountId) {
        try {
          const result = await fetchPaymentFees({ id: accountId, filters });
          setAccountPaymentFees(result.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    if (accountId) {
      getPaymentFeesByAccountId();
    }
  }, [accountId, fetchPaymentFees, filters]);

  const defaultColumns: ColumnDef<PaymentFee>[] = [
    {
      accessorKey: 'paymentType',
      header: 'Type',
      enableGlobalFilter: true,
      cell: ({
        row: {
          original: { paymentType },
        },
      }) => {
        if (!paymentType) return '';
        return paymentType.charAt(0).toUpperCase() + paymentType.slice(1);
      },
    },
    {
      accessorKey: 'currency',
      header: 'Currency',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'chargeType',
      header: 'Charge Type',
      enableGlobalFilter: true,
      cell: ({
        row: {
          original: { chargeType },
        },
      }) => {
        if (!chargeType) return '';
        return chargeType.toUpperCase();
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableGlobalFilter: true,
    },
  ];

  const accountColumns: ColumnDef<PaymentFee>[] = [
    {
      accessorKey: 'paymentType',
      header: 'Type',
      enableGlobalFilter: true,
      cell: ({
        row: {
          original: { paymentType },
        },
      }) => {
        if (!paymentType) return '';
        return paymentType.charAt(0).toUpperCase() + paymentType.slice(1);
      },
    },
    {
      accessorKey: 'currency',
      header: 'Currency',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'chargeType',
      header: 'Charge Type',
      enableGlobalFilter: true,
      cell: ({
        row: {
          original: { chargeType },
        },
      }) => {
        if (!chargeType) return '';
        return chargeType.toUpperCase();
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableGlobalFilter: true,
    },
  ];

  const defaultTable = useReactTable({
    data: defaultPaymentFees || [],
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const accountTable = useReactTable({
    data: accountPaymentFees || [],
    columns: accountColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PageWrapper>
      <header className="mb-16 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">Payment Fees</h1>
        </div>
      </header>

      <Tabs
        defaultValue={searchParams.accountId ? 'perAccount' : 'defaults'}
        onValueChange={() => resetFilters()}
      >
        <TabsList className="grid w-[250px] grid-cols-2">
          <TabsTrigger value="defaults">Defaults</TabsTrigger>
          <TabsTrigger value="perAccount">Per account</TabsTrigger>
        </TabsList>
        <TabsContent value="defaults">
          <div className="mt-10">
            <div className="flex items-center gap-4">
              <div className="flex w-[280px] flex-col items-start gap-1.5">
                <Label>Type</Label>
                <PaymentTypePicker
                  value={filters?.paymentType}
                  onChange={(value) => {
                    setFilters({ paymentType: value?.value as PaymentTypes });
                  }}
                />
              </div>
              <div className="flex w-[280px] flex-col items-start gap-1.5">
                <Label>Currency</Label>
                <CurrencyPicker
                  value={filters?.currency}
                  onChange={(value) => {
                    setFilters({ currency: value?.value });
                  }}
                  label="Select currency"
                  placeholder="Search currency."
                />
              </div>
              <Button className="self-end" onClick={resetFilters}>
                Reset
              </Button>
            </div>
            <DataTable<PaymentFee>
              cellClassName="h-16 p-2"
              table={defaultTable}
              onRowClick={({ paymentFeeDefaultId }) => {
                navigate({
                  to: '/payments/$id',
                  params: { id: paymentFeeDefaultId },
                  search: { ...filters },
                });
              }}
            />
          </div>
        </TabsContent>
        <TabsContent value="perAccount">
          <div className="mt-5 flex flex-col gap-1">
            <Label className="mb-2 text-sm font-medium text-slate-900">
              Select account
            </Label>
            <AccountPicker
              value={accountId}
              onChange={(value) => {
                setAccountId(value?.value);
              }}
            />
            {accountId && (
              <div className="mt-5">
                <div className="flex items-center gap-4">
                  <div className="flex w-[280px] flex-col items-start gap-1.5">
                    <Label>Type</Label>
                    <PaymentTypePicker
                      value={filters?.paymentType}
                      onChange={(value) => {
                        setFilters({
                          paymentType: value?.value as PaymentTypes,
                        });
                      }}
                    />
                  </div>
                  <div className="flex w-[280px] flex-col items-start gap-1.5">
                    <Label>Currency</Label>
                    <CurrencyPicker
                      value={filters?.currency}
                      onChange={(value) => {
                        setFilters({ currency: value?.value });
                      }}
                      label="Select currency"
                      placeholder="Search currency."
                    />
                  </div>
                  <Button className="self-end" onClick={resetFilters}>
                    Reset
                  </Button>
                </div>
                <DataTable<PaymentFee>
                  cellClassName="h-16 p-2"
                  table={accountTable}
                  onRowClick={({ paymentFeeId }) => {
                    navigate({
                      to: '/payments/$id',
                      params: { id: paymentFeeId },
                      search: { accountId, ...filters },
                    });
                  }}
                />
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </PageWrapper>
  );
}

export default PaymentsPage;
