import { Combobox, ComboboxItem } from '@agyt/client/shared/ui/components';

export interface PaymentType extends ComboboxItem {
  value: string;
  label: string;
}

export interface AccountPickerProps {
  value?: string;
  onChange: (value: PaymentType | undefined) => void;
}

const paymentTypes: PaymentType[] = [
  {
    value: 'local',
    label: 'LOCAL',
  },
  {
    value: 'swift',
    label: 'SWIFT',
  },
];

export function PaymentTypePicker({ value, onChange }: AccountPickerProps) {
  return (
    <Combobox<PaymentType>
      items={paymentTypes}
      emptyLabel=""
      placeholder=""
      selectedItem={paymentTypes.find((type) => type.value === value)}
      onSelect={onChange}
      disableSearch
    />
  );
}

export default PaymentTypePicker;
