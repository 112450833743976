import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/backoffice/core/http';
import { SuccessApiResponse, AccountResponse } from '@agyt/shared/types';

const ACCOUNTS_CACHE_KEY = 'accounts';

export function useFindAccounts() {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [ACCOUNTS_CACHE_KEY],
    async queryFn() {
      const res = await httpClient.get(`/accounts`);
      return res?.data as SuccessApiResponse<AccountResponse[]>;
    },
    placeholderData: keepPreviousData,
  });
}

export function useGetAccountById(id: string) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [ACCOUNTS_CACHE_KEY, id],
    async queryFn() {
      const res = await httpClient.get(`/accounts/${id}`);
      return res?.data as SuccessApiResponse<AccountResponse>;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });
}
