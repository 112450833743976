import {
  useFindPaymentFeeById,
  useUpdatePaymentFee,
} from '@agyt/client/backoffice/data-access/api';
import {
  PageWrapper,
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbPage,
  BreadcrumbSeparator,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
  Input,
  LoadingButton,
  InputWithSuffix,
} from '@agyt/client/shared/ui/components';
import { Link, useNavigate, useParams } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { ChargeType, PaymentTypes } from '@agyt/shared/util/common';
import { PaymentFee } from '@agyt/shared/types';
import { paymentsDetailsRoute } from './payments-page';

const paymentFeeForm = z.object({
  amount: z.string().min(1, {
    message: 'required',
  }),
  currency: z.string(),
  paymentType: z.union([
    z.enum([PaymentTypes.LOCAL, PaymentTypes.SWIFT]),
    z.string(),
  ]),
  chargeType: z
    .union([
      z.enum([ChargeType.OURS, ChargeType.SHARED, ChargeType.THEIRS]),
      z.string(),
    ])
    .optional(),
});

export type PaymentFeeForm = z.infer<typeof paymentFeeForm>;

function PaymentsDetailsPage() {
  const { id } = useParams({ strict: false });
  const searchParams = paymentsDetailsRoute.useSearch();
  const navigate = useNavigate({ from: '/payments/$id' });
  const [paymentFee, setPaymentFee] = useState<PaymentFee>();
  const { mutateAsync: fetchPaymentFeeById } = useFindPaymentFeeById();
  const { mutateAsync: updatePaymentFee } = useUpdatePaymentFee();

  useEffect(() => {
    const getPaymentFeeById = async () => {
      try {
        const result = await fetchPaymentFeeById({
          id,
          ...(searchParams.accountId && { accountId: searchParams.accountId }),
        });
        setPaymentFee(result?.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (id) {
      getPaymentFeeById();
    }
  }, []);

  const defaultValues = useMemo(() => {
    const emptyValues = {
      amount: '',
      currency: '',
      paymentType: '',
      chargeType: '',
    };
    if (!paymentFee) {
      return emptyValues;
    }

    return {
      amount: paymentFee.amount.toString(),
      currency: paymentFee.currency,
      paymentType: paymentFee.paymentType,
      chargeType: paymentFee.chargeType || '',
    };
  }, [paymentFee]);

  const form = useForm<PaymentFeeForm>({
    resolver: zodResolver(paymentFeeForm),
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  async function onSubmit(data: PaymentFeeForm) {
    try {
      const payload = {
        ...(searchParams.accountId
          ? { accountId: searchParams.accountId, paymentFeeId: id }
          : { paymentFeeDefaultId: id }),
        amount: Number(data.amount),
      };

      await updatePaymentFee(payload);

      if (searchParams.accountId) {
        navigate({
          to: '/payments',
          search: { accountId: searchParams.accountId, ...searchParams },
        });
      } else {
        navigate({
          to: '/payments',
          search: { ...searchParams },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PageWrapper className="flex flex-col">
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <Link to="/payments" className="cursor-pointer">
                Payment Fees
              </Link>
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <header className="mb-10 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">Payment Fee Details</h1>
        </div>
      </header>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mb-4 flex w-[285px] flex-col">
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                    Amount
                  </FormLabel>
                  <FormControl>
                    <InputWithSuffix {...field} suffix="EUR" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="currency"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                    Currency
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="paymentType"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                    Payment Type
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {paymentFee?.paymentType === PaymentTypes.SWIFT && (
              <FormField
                control={form.control}
                name="chargeType"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                      Charge Type
                    </FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </div>

          <LoadingButton
            type="submit"
            className="w-[100px]"
            isLoading={false}
            disabled={!form.formState.isDirty}
          >
            Save
          </LoadingButton>
        </form>
      </Form>
    </PageWrapper>
  );
}

export default PaymentsDetailsPage;
