import {
  useFindFxMarginFeeById,
  useUpdateFxMargin,
} from '@agyt/client/backoffice/data-access/api';
import { Link, useNavigate, useParams } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  PageWrapper,
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbPage,
  BreadcrumbSeparator,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  Input,
  FormMessage,
  Form,
  LoadingButton,
  InputWithSuffix,
} from '@agyt/client/shared/ui/components';
import { fxDetailsRoute } from './currency-exchange-page';
import { FxMargin } from '@agyt/shared/types';

const fxMarginForm = z.object({
  markup: z.string().min(1, {
    message: 'required',
  }),
  sellCurrency: z.string(),
  buyCurrency: z.string(),
});

export type FxMarginForm = z.infer<typeof fxMarginForm>;

export function CurrencyExchangeDetailsPage() {
  const { id } = useParams({ strict: false });
  const searchParams = fxDetailsRoute.useSearch();
  const navigate = useNavigate({ from: '/currency-exchanges/$id' });
  const [fxMargin, setFxMargin] = useState<FxMargin>();
  const { mutateAsync: fetchFxMarginFeeById } = useFindFxMarginFeeById();
  const { mutateAsync: updateFxMargin } = useUpdateFxMargin();

  useEffect(() => {
    const getFxMarginFeeById = async () => {
      try {
        const result = await fetchFxMarginFeeById({
          id,
          ...(searchParams.accountId && { accountId: searchParams.accountId }),
        });
        setFxMargin(result?.data);
      } catch (err) {
        console.error(err);
      }
    };
    if (id) {
      getFxMarginFeeById();
    }
  }, []);

  const defaultValues = useMemo(() => {
    const emptyValues = {
      markup: '',
      sellCurrency: '',
      buyCurrency: '',
    };
    if (!fxMargin) {
      return emptyValues;
    }

    return {
      markup: fxMargin.markup.toString(),
      sellCurrency: fxMargin.sellCurrency,
      buyCurrency: fxMargin.buyCurrency,
    };
  }, [fxMargin]);

  const form = useForm<FxMarginForm>({
    resolver: zodResolver(fxMarginForm),
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  async function onSubmit(data: FxMarginForm) {
    try {
      const payload = {
        ...(searchParams.accountId
          ? { accountId: searchParams.accountId, fxFeeId: id }
          : { fxFeeDefaultId: id }),
        markup: Number(data.markup),
      };

      await updateFxMargin(payload);

      if (searchParams.accountId) {
        navigate({
          to: '/currency-exchanges',
          search: { accountId: searchParams.accountId, ...searchParams },
        });
      } else {
        navigate({
          to: '/currency-exchanges',
          search: { ...searchParams },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <PageWrapper className="flex flex-col">
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <Link to="/currency-exchanges" className="cursor-pointer">
                Currency Exchanges
              </Link>
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <header className="mb-10 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">FX Markup</h1>
        </div>
      </header>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mb-4 flex w-[285px] flex-col">
            <FormField
              control={form.control}
              name="markup"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                    Markup
                  </FormLabel>
                  <FormControl>
                    <InputWithSuffix {...field} suffix="%" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="sellCurrency"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                    Sell Currency
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="buyCurrency"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                    Buy Currency
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <LoadingButton
            type="submit"
            className="w-[100px]"
            isLoading={false}
            disabled={!form.formState.isDirty}
          >
            Save
          </LoadingButton>
        </form>
      </Form>
    </PageWrapper>
  );
}

export default CurrencyExchangeDetailsPage;
