import { createRoute, useNavigate } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/backoffice/feature/common';
import { useEffect, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  AccountPicker,
  Button,
  CurrencyPicker,
  DataTable,
  Label,
  PageWrapper,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@agyt/client/shared/ui/components';
import { useFindFxMargins } from '@agyt/client/backoffice/data-access/api';
import { FxMargin } from '@agyt/shared/types';
import CurrencyExchangeDetailsPage from './currency-exchange-details-page';
import { useFilters } from './table-filters';

const fxRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/currency-exchanges',
  validateSearch: (search: Record<string, unknown>): { accountId?: string } =>
    search.accountId
      ? {
          accountId: String(search.accountId),
        }
      : {},
});

const fxIndexRoute = createRoute({
  getParentRoute: () => fxRoot,
  path: '/',
  component: CurrencyExchangePage,
});

export const fxDetailsRoute = createRoute({
  getParentRoute: () => fxRoot,
  path: '/$id',
  component: CurrencyExchangeDetailsPage,
  validateSearch: (
    search: Record<string, unknown>,
  ): { accountId?: string; returnTo?: string } =>
    search.accountId || search.returnTo
      ? {
          accountId: String(search.accountId),
          returnTo: String(search.returnTo),
        }
      : {},
});

export const fxRoutes = fxRoot.addChildren([fxIndexRoute, fxDetailsRoute]);

function EmptyListView() {
  return (
    <section className="mt-5 flex h-[436px] w-full flex-col items-center justify-center rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex max-w-min flex-col items-center justify-center">
        <h2 className="text-nowrap text-xl font-medium text-slate-900">
          No results.
        </h2>
      </div>
    </section>
  );
}

function CurrencyExchangePage() {
  const { filters, resetFilters, setFilters } = useFilters(fxRoot.id);
  const navigate = useNavigate({ from: '/currency-exchanges/$id' });
  const searchParams = fxRoot.useSearch();
  const [defaultMargins, setDefaultMargins] = useState<FxMargin[]>([]);
  const [accountMargins, setAccountMargins] = useState<FxMargin[]>([]);
  const [accountId, setAccountId] = useState<string | undefined>(
    searchParams.accountId,
  );
  const { mutateAsync: fetchFxMargins } = useFindFxMargins();

  useEffect(() => {
    const getFxMargins = async () => {
      try {
        const results = await fetchFxMargins({
          filters,
        });
        setDefaultMargins(results?.data);
      } catch (err) {
        console.log(err);
      }
    };
    getFxMargins();
  }, [filters, fetchFxMargins]);

  useEffect(() => {
    const getPaymentFeesByAccountId = async () => {
      if (accountId) {
        try {
          const result = await fetchFxMargins({ id: accountId, filters });
          setAccountMargins(result?.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    if (accountId) {
      getPaymentFeesByAccountId();
    }
  }, [accountId, fetchFxMargins, filters]);

  const defaultColumns: ColumnDef<FxMargin>[] = [
    {
      accessorKey: 'buyCurrency',
      header: 'Buy Currency',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'sellCurrency',
      header: 'Sell Currency',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'markup',
      header: 'Markup %',
      enableGlobalFilter: true,
    },
  ];

  const accountColumns: ColumnDef<FxMargin>[] = [
    {
      accessorKey: 'buyCurrency',
      header: 'Buy Currency',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'sellCurrency',
      header: 'Sell Currency',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'markup',
      header: 'Markup %',
      enableGlobalFilter: true,
    },
  ];

  const defaultTable = useReactTable({
    data: defaultMargins || [],
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const accountTable = useReactTable({
    data: accountMargins || [],
    columns: accountColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PageWrapper>
      <header className="mb-16 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">Currency Exchange Margins</h1>
        </div>
      </header>

      <Tabs
        defaultValue={searchParams.accountId ? 'perAccount' : 'defaults'}
        onValueChange={() => resetFilters()}
      >
        <TabsList className="grid w-[250px] grid-cols-2">
          <TabsTrigger value="defaults">Defaults</TabsTrigger>
          <TabsTrigger value="perAccount">Per account</TabsTrigger>
        </TabsList>
        <TabsContent value="defaults">
          <div className="mt-10">
            <div className="flex items-center gap-4">
              <div className="flex w-[280px] flex-col items-start gap-1.5">
                <Label>Buy Currency</Label>
                <CurrencyPicker
                  value={filters?.buyCurrency}
                  onChange={(value) => {
                    setFilters({ buyCurrency: value?.value });
                  }}
                  label="Select currency"
                  placeholder="Search currency."
                />
              </div>
              <div className="flex w-[280px] flex-col items-start gap-1.5">
                <Label>Sell Currency</Label>
                <CurrencyPicker
                  value={filters?.sellCurrency}
                  onChange={(value) => {
                    setFilters({ sellCurrency: value?.value });
                  }}
                  label="Select currency"
                  placeholder="Search currency."
                />
              </div>
              <Button className="self-end" onClick={resetFilters}>
                Reset
              </Button>
            </div>
            {!defaultMargins.length ? (
              <EmptyListView />
            ) : (
              <DataTable<FxMargin>
                cellClassName="h-16 p-2"
                table={defaultTable}
                onRowClick={({ fxFeeDefaultId }) => {
                  navigate({
                    to: '/currency-exchanges/$id',
                    params: { id: fxFeeDefaultId },
                    search: { ...filters },
                  });
                }}
              />
            )}
          </div>
        </TabsContent>
        <TabsContent value="perAccount">
          <div className="mt-5 flex flex-col gap-1">
            <Label className="mb-2 text-sm font-medium text-slate-900">
              Select account
            </Label>
            <AccountPicker
              value={accountId}
              onChange={(value) => setAccountId(value?.value)}
            />
            {accountId && (
              <div className="mt-5">
                <div className="flex items-center gap-4">
                  <div className="flex w-[280px] flex-col items-start gap-1.5">
                    <Label>Buy Currency</Label>
                    <CurrencyPicker
                      value={filters?.buyCurrency}
                      onChange={(value) => {
                        setFilters({ buyCurrency: value?.value });
                      }}
                      label="Select currency"
                      placeholder="Search currency."
                    />
                  </div>
                  <div className="flex w-[280px] flex-col items-start gap-1.5">
                    <Label>Sell Currency</Label>
                    <CurrencyPicker
                      value={filters?.sellCurrency}
                      onChange={(value) => {
                        setFilters({ sellCurrency: value?.value });
                      }}
                      label="Select currency"
                      placeholder="Search currency."
                    />
                  </div>
                  <Button className="self-end" onClick={resetFilters}>
                    Reset
                  </Button>
                </div>
                {!accountMargins.length ? (
                  <EmptyListView />
                ) : (
                  <DataTable<FxMargin>
                    cellClassName="h-16 p-2"
                    table={accountTable}
                    onRowClick={({ fxFeeId }) => {
                      navigate({
                        to: '/currency-exchanges/$id',
                        params: { id: fxFeeId },
                        search: { accountId, ...filters },
                      });
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </PageWrapper>
  );
}

export default CurrencyExchangePage;
