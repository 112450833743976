import { useFindAccounts } from '@agyt/client/backoffice/data-access/api';
import { Combobox, ComboboxItem } from './combobox';

export interface Account extends ComboboxItem {
  value: string;
  label: string;
}

export interface AccountPickerProps {
  value?: string;
  onChange: (value: Account | undefined) => void;
}

export function AccountPicker({ value, onChange }: AccountPickerProps) {
  const { data: accountsData } = useFindAccounts();

  const accounts = accountsData?.data.map((account) => ({
    value: account.id,
    label: account.accountName,
  }));

  return (
    <Combobox<Account>
      items={accounts || []}
      emptyLabel=""
      placeholder=""
      selectedItem={value ? { value, label: value } : undefined}
      onSelect={onChange}
      disableSearch
      className="w-[250px]"
    />
  );
}

export default AccountPicker;
