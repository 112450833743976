import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/backoffice/core/http';
import {
  SuccessApiResponse,
  PaymentFeeResponse,
  PaymentFeePayloadBody,
  PaymentFeeDefaultResponse,
  PaymentFeesCriteriaQuery,
} from '@agyt/shared/types';

const PAYMENT_FEES_CACHE_KEY = 'payment-fees';

export function useFindPaymentFees() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async ({
      id,
      filters,
    }: {
      id?: string;
      filters: Partial<PaymentFeesCriteriaQuery>;
    }) => {
      try {
        const res = await httpClient.get(`/payments/fees`, {
          params: { accountId: id, filter: filters },
        });
        return res?.data as SuccessApiResponse<PaymentFeeDefaultResponse[]>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'findPaymentFees',
    },
  });
}

export function useFindPaymentFeeById() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async ({
      id,
      accountId,
    }: {
      id?: string;
      accountId?: string;
    }) => {
      const res = await httpClient.get(`/payments/fees/${id}`, {
        params: { accountId },
      });
      return res?.data as SuccessApiResponse<PaymentFeeResponse>;
    },
    scope: {
      id: 'findPaymentFeeById',
    },
  });
}

export function useUpdatePaymentFee() {
  const { httpClient } = useHttpClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PaymentFeePayloadBody) => {
      try {
        const res = await httpClient.post('/payments/fees', data);
        return res.data as SuccessApiResponse<PaymentFeeResponse>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'updatePaymentFee',
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [PAYMENT_FEES_CACHE_KEY],
      });
    },
  });
}
