import {
  createRoute,
  Navigate,
  Outlet,
  redirect,
} from '@tanstack/react-router';
import Navigation from './navigation';
import { rootRoute } from './root-route';
import { useAuth0 } from '@auth0/auth0-react';

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'index',
  component: IndexPage,
  beforeLoad: ({ context, location }) => {
    const { isLoading, isAuthenticated } = context.auth;
    if (!isLoading && !isAuthenticated) {
      throw redirect({
        to: '/welcome',
        search: {
          returnTo: location.href,
        },
      });
    }
  },
});

export function IndexPage() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    return <Navigate to="/welcome" />;
  }

  return (
    <div className="flex h-full w-full">
      <Navigation />
      <div className="flex-1 overflow-y-auto bg-slate-50">
        <Outlet />
      </div>
    </div>
  );
}

export default IndexPage;
