import {
  FxMarginsCriteriaQuery,
  TransactionCriteriaQuery,
} from '@agyt/shared/types';
import {
  getRouteApi,
  RegisteredRouter,
  RouteIds,
  useNavigate,
} from '@tanstack/react-router';

export const cleanEmptyParams = <T extends Record<string, unknown>>(
  search: T,
) => {
  const newSearch = { ...search };
  Object.keys(newSearch).forEach((key) => {
    const value = newSearch[key];
    if (
      value === undefined ||
      value === '' ||
      (typeof value === 'number' && isNaN(value))
    )
      delete newSearch[key];
  });

  return newSearch;
};

export function useFilters<T extends RouteIds<RegisteredRouter['routeTree']>>(
  routeId: T,
) {
  const routeApi = getRouteApi(routeId);
  const navigate = useNavigate();
  const filters = routeApi.useSearch() as Partial<FxMarginsCriteriaQuery>;

  const setFilters = (partialFilters: Partial<FxMarginsCriteriaQuery>) =>
    navigate({
      to: '.',
      search: (prev: any) => cleanEmptyParams({ ...prev, ...partialFilters }),
    });
  const resetFilters = () => navigate({ to: '.', search: {} });

  return { filters, setFilters, resetFilters };
}
