import { useGetAccountById } from '@agyt/client/backoffice/data-access/api';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  DetailsCard,
  PageWrapper,
} from '@agyt/client/shared/ui/components';
import { Link, useParams } from '@tanstack/react-router';
import { useMemo } from 'react';

const accountDetailOrderMap: {
  [key: string]: {
    order: number;
    label: string;
  };
} = {
  accountName: { order: 0, label: 'Name' },
  street: { order: 1, label: 'Address' },
  city: { order: 2, label: 'City' },
  country: { order: 3, label: 'Country' },
  status: { order: 4, label: 'Status' },
};

function UsersDetailsPage() {
  const { id } = useParams({ strict: false });
  const { data } = useGetAccountById(id);
  const accountDetails = useMemo(() => data?.data, [data]);

  const account = useMemo(() => {
    if (!accountDetails) {
      return [];
    }

    return Object.keys(accountDetailOrderMap).map((key) => {
      const { label } = accountDetailOrderMap[key];

      if (key === 'status') {
        const value = (accountDetails as any)[key];
        return {
          key: label,
          value: value?.charAt(0).toUpperCase() + value?.slice(1),
        };
      }

      return {
        key: label,
        value: (accountDetails as any)[key],
      };
    });
  }, [accountDetails]);

  return (
    <PageWrapper>
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <Link to="/accounts" className="cursor-pointer">
                Accounts
              </Link>
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">Account Details Page</h1>
        </div>
      </header>

      {!!account.length && (
        <section className="w-1/2">
          <DetailsCard data={account} />
        </section>
      )}
    </PageWrapper>
  );
}

export default UsersDetailsPage;
