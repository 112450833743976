import { createRoute, Link, useNavigate } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/backoffice/feature/common';
import {
  Button,
  DataTable,
  PageWrapper,
} from '@agyt/client/shared/ui/components';
import { useEffect, useState } from 'react';
import { CcAccount } from '@agyt/shared/types';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useFindAccounts } from '@agyt/client/backoffice/data-access/api';
import { IconArrowLeftRight, IconWallet } from '@agyt/client/shared/ui/icons';
import UsersDetailsPage from './users-details-page';

export const usersRoute = createRoute({
  getParentRoute: () => indexRoute,
  path: 'accounts',
});

export const usersIndexRoute = createRoute({
  getParentRoute: () => usersRoute,
  path: '/',
  component: UsersPage,
});

const usersDetailsRoute = createRoute({
  getParentRoute: () => usersRoute,
  path: '$id',
  component: UsersDetailsPage,
});

export const usersRoutes = usersRoute.addChildren([
  usersIndexRoute,
  usersDetailsRoute,
]);

function UsersPage() {
  const navigate = useNavigate({ from: '/accounts/$id' });
  const [users, setUsers] = useState<CcAccount[]>([]);
  const { data: accountsData } = useFindAccounts();

  useEffect(() => {
    if (accountsData) {
      setUsers(accountsData.data);
    }
  }, [accountsData]);

  const columns: ColumnDef<CcAccount>[] = [
    {
      accessorKey: 'accountName',
      header: 'Name',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableGlobalFilter: true,
      cell: ({ row: { original } }) =>
        original.status.charAt(0).toUpperCase() + original.status.slice(1),
    },
    {
      accessorKey: '',
      header: 'Actions',
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex items-center gap-2">
            <Link
              to="/payments"
              search={{ accountId: original.id }}
              onClick={(e) => e.stopPropagation()}
            >
              <Button>
                <IconWallet />
              </Button>
            </Link>
            <Link
              to="/currency-exchanges"
              search={{ accountId: original.id }}
              onClick={(e) => e.stopPropagation()}
            >
              <Button>
                <IconArrowLeftRight />
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data: users || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PageWrapper>
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">Accounts</h1>
        </div>
      </header>

      <DataTable<CcAccount>
        cellClassName="h-16 p-2"
        table={table}
        onRowClick={({ id }) => {
          navigate({
            to: '/accounts/$id',
            params: { id },
          });
        }}
      />
    </PageWrapper>
  );
}

export default UsersPage;
